
/*
  Side Bar component

  displays the current services, allows additions of new ones
*/
// Libs
import Vue from 'vue'

// Constants / Interfaces
import { IService } from '@/models/service.interfaces'
import { IServer } from '@/models/server.interfaces'
import { AddServiceDialogProps } from '@/controllers/dialog.interfaces'

// Components
import store from '@/store'

// Controllers & Services
import AppCtrl from '@/controllers/app-ctrl.model'

interface ServiceDisplay {
  id: string
  path: string
  isError: string | null
}

const serviceDisplaySort = function (a: ServiceDisplay, b: ServiceDisplay) {
  const aPath = a.path.toLowerCase()
  const bPath = b.path.toLowerCase()
  if (aPath < bPath) {
    return -1
  }
  if (aPath > bPath) {
    return 1
  }
  return 0
}

/*
  Vuejs Interfaces
*/
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IData {}

interface IComputed {
  currentServerId: string | null
  currentServerInstance: IServer | null
  haveServer: boolean
  serviceDisplayItems: ServiceDisplay[]
  servicePaths: string[]
}

interface IMethods {
  _handleAddService(): void
  _handleRemoveService(service: ServiceDisplay): void
  _handleSelectService(service: ServiceDisplay): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  name: 'SideBar',

  computed: {
    currentServerId() {
      return store.state.currentServerId
    },

    currentServerInstance() {
      return store.state.serverInstance
    },

    haveServer() {
      return this.currentServerInstance !== null
    },

    serviceDisplayItems() {
      const { serviceInstances } = store.state
      return Object.values(serviceInstances)
        .map((service: IService) => {
          const { isError, path, id } = service
          return {
            id,
            path,
            isError,
          }
        })
        .sort(serviceDisplaySort)
    },

    servicePaths() {
      const { serviceInstances } = store.state
      return Object.values(serviceInstances).map(
        (service: IService) => service.path
      )
    },
  },

  methods: {
    _handleAddService() {
      const { servicePaths } = this
      const props: AddServiceDialogProps = {
        current: null,
        existing: servicePaths,
      }
      void AppCtrl.showAddServiceDialog(props)
    },

    _handleRemoveService(service: ServiceDisplay) {
      AppCtrl.removeService(service.id)
    },

    _handleSelectService(service: ServiceDisplay) {
      AppCtrl.setCurrentServiceId(service.id)
    },
  },
})
