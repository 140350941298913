
// Libs
import Vue from 'vue'

// Components
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppMain from '@/components/AppMain.vue'

// Controllers & Services
import DialogController from '@/components/DialogController.vue'

export default Vue.extend({
  name: 'App',

  components: {
    AppFooter,
    AppHeader,
    AppMain,
    DialogController,
  },
})
