
// Libs
import Vue from 'vue'

// Constants & Interfaces
import { Server, ServerHash } from '@/models/server.interfaces'

// Controllers & Services
import AppCtrl from '@/controllers/app-ctrl.model'

interface SelectOption {
  value: string
  label: string
}

/*
  Vuejs Interfaces
*/
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

interface IData {
  selectedServerId: string
}

interface IComputed {
  currentServerId: string | null
  serverConfigs: ServerHash
  serversList: Server[]
  selectOptions: SelectOption[]
}

interface IMethods {
  _handleServerSelect(serverId: string): void
  _handleShowSettings(): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  data() {
    return {
      selectedServerId: '',
    }
  },

  computed: {
    currentServerId() {
      return AppCtrl.currentServerId
    },

    serverConfigs() {
      return AppCtrl.serverConfigs
    },

    serversList() {
      return AppCtrl.serversList
    },

    selectOptions() {
      const { serversList } = this

      return serversList.map(function (item) {
        const { id, url, name } = item
        const label = name !== '' ? `${name} (${url})` : url
        return {
          value: id,
          label,
        }
      })
    },
  },

  methods: {
    _handleServerSelect(serverId: string) {
      const { serverConfigs } = this

      const serverConfig = serverConfigs[serverId]
      AppCtrl.activateServer(serverConfig)
    },

    _handleShowSettings() {
      void AppCtrl.showManageServersDialog()
    },
  },

  mounted() {
    const { currentServerId } = this
    this.selectedServerId = currentServerId || ''
  },
})
