
// Libs
import Vue from 'vue'

// Components
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'

export default Vue.extend({

  name: 'HomeView',

  components: {
    AppFooter,
    AppHeader,
  },

})
